<template>
  <div>
    <nav-bar
      v-if="!loading"
      :outlet-info="outletDetails"
    />
    <!-- <router-view /> -->
  </div>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import NavBar from '../components/NavBar.vue'
import { userService } from '../api/userService'
import { useRouter } from '../utils/utils'

export default {
  components: {
    NavBar,
  },
  setup() {
    const { router } = useRouter()

    const loading = ref(true)

    const outletDetails = ref(null)

    userService.setOutlet(router.currentRoute.params.id).then(res => {
      if (res.data) {
        const bodyStyles = document.body.style
        // const root = document.querySelector(':root')

        bodyStyles.setProperty(
          '--primary-color',
          res.data.online_store_settings.theme.primaryColor,
        )
        bodyStyles.setProperty(
          '--secondary-color',
          res.data.online_store_settings.theme.secondaryColor,
        )

        bodyStyles.setProperty('--logo-url', res.data.logo_online)
        bodyStyles.setProperty('--banner-url', res.data.banner_online)

        store.state.outlet.outletInfo = res.data

        if (!res.data.is_online) {
          router.push({ name: '404' })
        }
        outletDetails.value = res.data
        loading.value = false
        if (res.data.holiday) {
          const index = res.data.holiday.findIndex(
            el => el.event_date === moment().format('YYYY-MM-DD'),
          )
          if (index !== -1) {
            router.push({ name: 'closed' })
          }
        }
      }
      // userService.getStoreDetails().then(res => {
      //   if (res.data) {
      //     store.state.outlet.outletInfo = res.data
      //     outletDetails.value = res.data
      //     loading.value = false
      //   }
      // })
    })
    return {
      loading,
      outletDetails,
    }
  },
}
</script>
