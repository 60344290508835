<template>
  <div class="content-wrapper">
    <div class="header top-header bg-none">
      <!-- Sin IN -->
      <sign-in
        :visible="isSignIn"
        @is-sign-in-active="isSignIn = false"
      />

      <v-container
        class="bg-cart-header"
        :class="{ 'header-bg': !outletDetails.banner_online != '' }"
      >
        <div class="navbar-banner">
          <img
            v-if="outletDetails.banner_online"
            class="nav-banner"
            :src="outletDetails.banner_online"
          >
        </div>
        <v-row class="cart-header align-center">
          <v-col
            md="4"
            sm="4"
            class="backarrow-wrapper"
          >
            <div
              class="delivery-detail toptitle-arrow"
              :class="$route.name == 'home' ? 'home-store' : ''"
            >
              <router-link
                v-if="$route.name !== 'home'"
                class="back-arrow"
                to=""
                @click.native="navigateToHome"
              >
                <!-- <svg

                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0C7.17706 0 0 7.17706 0 16C0 24.8229 7.17706 32 16 32C24.8229 32 32 24.8229 32 16C32 7.17706 24.8229 0 16 0ZM16 30.6667C7.91275 30.6667 1.33331 24.0872 1.33331 16C1.33331 7.91275 7.91275 1.33331 16 1.33331C24.0872 1.33331 30.6667 7.91275 30.6667 16C30.6667 24.0872 24.0872 30.6667 16 30.6667Z"
                    fill="#B2B6C1"
                  />
                  <path
                    d="M19.138 8.19533C18.8776 7.93489 18.4557 7.93489 18.1953 8.19533L10.862 15.5286C10.6016 15.7891 10.6016 16.2109 10.862 16.4713L18.1953 23.8046C18.3255 23.9348 18.4961 23.9999 18.6667 23.9999C18.8373 23.9999 19.0078 23.9348 19.1381 23.8046C19.3985 23.5442 19.3985 23.1223 19.1381 22.8619L12.2761 16L19.1381 9.13801C19.3985 8.87764 19.3985 8.45577 19.138 8.19533Z"
                    fill="#B2B6C1"
                  />
                </svg> -->
                <svg
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                >
                  <rect
                    fill="none"
                    height="25"
                    width="25"
                  />
                  <path
                    d="M120,32,24,128l96,96V176h88a8,8,0,0,0,8-8V88a8,8,0,0,0-8-8H120Z"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="16"
                  />
                </svg>
              </router-link>
            </div>
            <div class="delivery-detail outletDetails">
              <h2 class="white--text">
                {{ outletDetails.name }}
              </h2>
              <p class="white--text">
                {{ outletDetails.address1 }}<br>
                {{ outletDetails.address2 }}<br>
                {{ outletDetails.address3 }}
              </p>
            </div>
          </v-col>
          <v-col
            md="4"
            sm="4"
          >
            <div class="d-flex align-items-center ml10">
              <v-img
                v-if="outletDetails.logo_online"
                class="brnd-logo"
                max-width="130"
                :src="outletDetails.logo_online"
              />
              <!-- <h2 class="ml-2">
                {{ outletDetails ? outletDetails.name : '' }}
              </h2> -->
            </div>
          </v-col>
          <v-col
            md="4"
            sm="4"
            class="d-flex align-center justify-end user-wrapper"
          >
            <!-- <div
              class="text-right"
              style="position:relative"
            > -->
            <div
              v-if="$store.state.user.activeUser.name"
              class="userName mr-3"
            >
              <!-- Welcome  {{ $store.state.user.activeUser && $store.state.user.activeUser.address ? $store.state.user.activeUser.address[0].name : '' }} -->
              Welcome
              {{
                $store.state.user.activeUser
                  ? $store.state.user.activeUser.name
                  : ''
              }}
            </div>

            <div>
              <v-menu
                class="nav-dropdown"
                content-class="v-menu-top"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    icon
                    class="button-one usericon"
                    v-on="on"
                  >
                    <v-icon class="icon-theme">
                      mdi-account-circle
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="$store.state.user.isLoggedIn">
                    <router-link :to="{ name: 'order' }">
                      <v-list-item-title>
                        <div class="d-flex align-center">
                          <v-img
                            src="@/assets/images/icons/orders.svg"
                            height="20"
                            width="19"
                          />
                          <div class="ml-2">
                            My Orders
                          </div>
                        </div>
                      </v-list-item-title>
                    </router-link>
                  </v-list-item>
                  <v-list-item v-if="$store.state.user.isLoggedIn">
                    <v-list-item-title
                      class="list"
                      @click="signOut"
                    >
                      <div class="d-flex align-center">
                        <v-img
                          src="@/assets/images/icons/lock_open.svg"
                          height="24"
                          width="18"
                          class="signOut"
                        />

                        <router-link
                          v-if="$route.name !== 'home'"
                          :to="{ name: 'home' }"
                        >
                          <div class="ml-2">
                            Signout
                          </div>
                        </router-link>
                        <div
                          v-if="$route.name == 'home'"
                          class="ml-2"
                        >
                          Signout
                        </div>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!$store.state.user.isLoggedIn">
                    <v-list-item-title
                      class="list"
                      @click="isSignIn = true"
                    >
                      SignIn
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-if="!$store.state.user.isLoggedIn">
                  <v-list-item-title
                    @click="isSignUp = true"
                  >
                    SignUp
                  </v-list-item-title>
                </v-list-item> -->
                </v-list>
              </v-menu>
            </div>
            <!-- </div> -->
          </v-col>
          <!-- <v-col cols="12">
          <div class="delivery-flex-div">
            <h4>Fast Food, Continental</h4>
            <h3>4.1 <span>500+ Ratings </span></h3>
            <h3>45 Mins <span>Delivery </span></h3>

          </div>
        </v-col> -->
        </v-row>

        <v-row class="search-row">
          <v-col
            sm="12"
            md="6"
            class="d-flex iconDiv justify-end align-center header-search-wraper"
          >
            <div
              v-if="$route.name == 'home'"
              class="d-flex search-box"
            >
              <v-text-field
                v-model="resultQuery"
                placeholder="Search for food ..."
                outlined
              />
              <v-img
                src="@/assets/images/icons/search-button.svg"
                alt="search"
                class="Icon"
              />
            </div>

            <div class="ml-4 d-flex">
              <v-btn
                class="info-bt"
                color="primary"
                dark
                icon
                @click="isStoreDetails = true"
              >
                <v-icon class="Icon icon-theme">
                  mdi-information
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        id="customize"
        ref="custom"
        v-model="isSignUp"
        width="500"
      >
        <v-container class="grey lighten-5">
          <validation-observer ref="observer">
            <v-form ref="form">
              <validation-provider
                v-slot="{ errors }"
                name="First Name"
                rules="required"
              >
                <v-text-field
                  v-model="userData.user.first_name"
                  label="First Name"
                  required
                  :error-messages="errors[0]"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Last Name"
                rules="required"
              >
                <v-text-field
                  v-model="userData.user.last_name"
                  label="Last Name"
                  :error-messages="errors[0]"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required"
              >
                <v-text-field
                  v-model="userData.user.user_name"
                  label="Email"
                  required
                  :error-messages="errors[0]"
                />
              </validation-provider>

              <v-btn @click="sumbitRegistraion">
                Submit
              </v-btn>
            </v-form>
          </validation-observer>
        </v-container>
      </v-dialog>

      <v-dialog
        id="store-detail"
        ref="custom"
        v-model="isStoreDetails"
        width="768"
        overlay-opacity="0.8"
      >
        <v-container class="grey lighten-5 outlet-info map-container">
          <h2>{{ outletDetails ? outletDetails.name : '' }}</h2>
          <div class="order-location">
            <!-- <Map /> -->
          </div>

          <div class="location-description">
            For reliable on screen review of your order status, in real-time,
            your data may be saved on this device by using cookies. Please read
            our Cookie Policy and change your settings at any time.
          </div>
          <v-row>
            <v-col cols="12">
              <h2>Opening Hours</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h4>Dine In</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="opening-list">
                    <!--------------------------------------------------- Sunday --------------------------------------------------->
                    <h6
                      v-if="
                        getallTime.sunday.dinein[0].isOpen == true &&
                          getallTime.sunday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Sunday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.sunday.dinein[0].isOpen == true &&
                          getallTime.sunday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Sunday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.sunday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Sunday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>
                    <!--------------------------------------------------- Monday --------------------------------------------------->
                    <h6
                      v-if="
                        getallTime.monday.dinein[0].isOpen == true &&
                          getallTime.monday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Monday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.monday.dinein[0].isOpen == true &&
                          getallTime.monday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Monday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.monday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      Monday <span>Closed</span>
                    </h6>
                    <!--------------------------------------------------- Tuesday --------------------------------------------------->

                    <h6
                      v-if="
                        getallTime.tuesday.dinein[0].isOpen == true &&
                          getallTime.tuesday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Tuesday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.tuesday.dinein[0].isOpen == true &&
                          getallTime.tuesday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Tuesday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.tuesday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      Tuesday <span>Closed</span>
                    </h6>
                    <!--------------------------------------------------- Wednesday --------------------------------------------------->

                    <h6
                      v-if="
                        getallTime.wednesday.dinein[0].isOpen == true &&
                          getallTime.wednesday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Wednesday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.wednesday.dinein[0].isOpen == true &&
                          getallTime.wednesday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Wednesday </span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.wednesday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      Wednesday <span>Closed</span>
                    </h6>

                    <!--------------------------------------------------- Thursday --------------------------------------------------->

                    <h6
                      v-if="
                        getallTime.thursday.dinein[0].isOpen == true &&
                          getallTime.thursday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Thursday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.thursday.dinein[0].isOpen == true &&
                          getallTime.thursday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Thursday </span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.thursday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      Thursday <span>Closed</span>
                    </h6>
                    <!--------------------------------------------------- Friday --------------------------------------------------->

                    <h6
                      v-if="
                        getallTime.friday.dinein[0].isOpen == true &&
                          getallTime.friday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Friday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.friday.dinein[0].isOpen == true &&
                          getallTime.friday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Friday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.friday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      Friday <span>Closed</span>
                    </h6>
                    <!--------------------------------------------------- Saturday --------------------------------------------------->

                    <h6
                      v-if="
                        getallTime.saturday.dinein[0].isOpen == true &&
                          getallTime.saturday.dinein[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Saturday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.saturday.dinein[0].isOpen == true &&
                          getallTime.saturday.dinein[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Saturday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.saturday.dinein"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Saturday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <h4>Collection</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="opening-list">
                    <h6
                      v-if="
                        getallTime.sunday.collection[0].isOpen == true &&
                          getallTime.sunday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date"> Sunday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.sunday.collection[0].isOpen == true &&
                          getallTime.sunday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date"> Sunday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.sunday.collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Sunday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.monday.collection[0].isOpen == true &&
                          getallTime.monday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Monday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.monday.collection[0].isOpen == true &&
                          getallTime.monday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date"> Monday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.monday.collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date"> Monday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.tuesday.collection[0].isOpen == true &&
                          getallTime.tuesday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date"> Tuesday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.tuesday.collection[0].isOpen == true &&
                          getallTime.tuesday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date"> Tuesday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.tuesday
                            .collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Tuesday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.wednesday.collection[0].isOpen == true &&
                          getallTime.wednesday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date">Wednesday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.wednesday.collection[0].isOpen == true &&
                          getallTime.wednesday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Wednesday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.wednesday
                            .collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Wednesday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.thursday.collection[0].isOpen == true &&
                          getallTime.thursday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date"> Thursday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.thursday.collection[0].isOpen == true &&
                          getallTime.thursday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Thursday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.thursday
                            .collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Thursday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.friday.collection[0].isOpen == true &&
                          getallTime.friday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date"> Friday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.friday.collection[0].isOpen == true &&
                          getallTime.friday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Friday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.friday.collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Friday</span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>

                    <h6
                      v-if="
                        getallTime.saturday.collection[0].isOpen == true &&
                          getallTime.saturday.collection[0].open == '24hrs'
                      "
                    >
                      <span class="opening-date"> Saturday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">24 Hrs</span>
                      </span>
                    </h6>
                    <h6
                      v-else-if="
                        getallTime.saturday.collection[0].isOpen == true &&
                          getallTime.saturday.collection[0].open !== '24hrs'
                      "
                    >
                      <span class="opening-date">Saturday</span>
                      <span class="time-wrap">
                        <span
                          v-for="(times, index) in getallTime.saturday
                            .collection"
                          :key="index"
                          class="opening-hours"
                        >
                          {{ formatval(times.open) }} -
                          {{ formatval(times.close) }}
                        </span>
                      </span>
                    </h6>
                    <h6 v-else>
                      <span class="opening-date">Saturday </span>
                      <span class="time-wrap">
                        <span class="opening-hours">Closed</span>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h4>Address</h4>
              <div class="outlet-details">
                {{ outletDetails ? outletDetails.address1 : '' }},
                {{ outletDetails ? outletDetails.address2 : '' }},
                {{ outletDetails ? outletDetails.address3 : '' }}.
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <h4>Payment Methods</h4>
              <ul class="pay-methods">
                <li>
                  <span class="lblwrap">
                    <span class="icon">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.84016 4.61144L6.22881 1.00001C6.12572 0.896917 5.98832 0.840332 5.84182 0.840332C5.69515 0.840332 5.55784 0.896998 5.45475 1.00001L5.12686 1.32797C5.02386 1.4309 4.96711 1.56837 4.96711 1.71496C4.96711 1.86146 5.02386 2.00357 5.12686 2.1065L7.23367 4.21795H0.540239C0.238453 4.21795 0 4.45421 0 4.75607V5.21973C0 5.5216 0.238453 5.78167 0.540239 5.78167H7.25758L5.12695 7.90491C5.02394 8.008 4.96719 8.14174 4.96719 8.28832C4.96719 8.43474 5.02394 8.57043 5.12695 8.67344L5.45483 9.00035C5.55792 9.10344 5.69523 9.15961 5.8419 9.15961C5.9884 9.15961 6.1258 9.10271 6.22889 8.99962L9.84024 5.38826C9.94357 5.28485 10.0004 5.1468 9.99999 5.00005C10.0003 4.85282 9.94357 4.71469 9.84016 4.61144Z"
                          fill="#B2B6C1"
                        />
                      </svg>
                    </span>
                    <span class="lbl">Dine In:</span>
                  </span>
                  <span
                    class="paylbl"
                  >Card{{
                    outletDetails &&
                      outletDetails.online_store_settings.store['dinein']
                        .no_payment
                      ? ',Cash'
                      : ''
                  }}</span>
                </li>
                <li>
                  <span class="lblwrap">
                    <span class="icon">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.84016 4.61144L6.22881 1.00001C6.12572 0.896917 5.98832 0.840332 5.84182 0.840332C5.69515 0.840332 5.55784 0.896998 5.45475 1.00001L5.12686 1.32797C5.02386 1.4309 4.96711 1.56837 4.96711 1.71496C4.96711 1.86146 5.02386 2.00357 5.12686 2.1065L7.23367 4.21795H0.540239C0.238453 4.21795 0 4.45421 0 4.75607V5.21973C0 5.5216 0.238453 5.78167 0.540239 5.78167H7.25758L5.12695 7.90491C5.02394 8.008 4.96719 8.14174 4.96719 8.28832C4.96719 8.43474 5.02394 8.57043 5.12695 8.67344L5.45483 9.00035C5.55792 9.10344 5.69523 9.15961 5.8419 9.15961C5.9884 9.15961 6.1258 9.10271 6.22889 8.99962L9.84024 5.38826C9.94357 5.28485 10.0004 5.1468 9.99999 5.00005C10.0003 4.85282 9.94357 4.71469 9.84016 4.61144Z"
                          fill="#B2B6C1"
                        />
                      </svg>
                    </span>
                    <span class="lbl">Collection:</span>
                  </span>
                  <span
                    class="paylbl"
                  >Card{{
                    outletDetails &&
                      outletDetails.online_store_settings.store['collection']
                        .no_payment
                      ? ',Cash'
                      : ''
                  }}</span>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h4>Phone</h4>
              <div class="phn-wrap">
                <h5>{{ outletDetails ? outletDetails.phone : '' }}</h5>
              </div>
            </v-col>
            <!-- <v-col
            cols="12"
            md="6"
          >
            <h4>Rating</h4>
            <p><span>528</span> Ratings</p>
          </v-col> -->
          </v-row>
        </v-container>
      </v-dialog>
    </div>
    <router-view />
    <service-not-avalibale :availability="blockStore" />
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import moment from 'moment'
import { userService } from '../api/userService'
import signIn from './SignIn.vue'
import { useRouter } from '../utils/utils'
import serviceNotAvalibale from './serviceNotAvalibale.vue'
// import Map from './Map.vue'

// import { useRouter } from '../utils/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    signIn,
    serviceNotAvalibale,
    // Map,
  },
  props: {
    outletInfo: {
      default: null,
      type: Object,
      requires: true,
    },
  },

  setup(props) {
    const isSignUp = ref(false)

    const isSignIn = ref(false)

    const blockStore = ref(false)

    const outletDetails = ref(null)
    // const { router } = useRouter()

    const { router } = useRouter()

    const resultQuery = ref(null)

    const isStoreDetails = ref(false)

    const tableNo = ref(router.currentRoute.params.table_no || '')

    const routeName = ref(router.currentRoute.name)

    const getallTime = store.state.outlet.outletInfo.online_business_hours

    const blocOrUnblock = () => {
      if (
        outletDetails
        && !outletDetails.value.online_store_settings.store.dinein.allow_dinein
        && !outletDetails.value.online_store_settings.store.collection
          .allow_collection
      ) {
        blockStore.value = true
      }

      if (
        outletDetails
        && !outletDetails.value.online_store_settings.store.dinein.allow_dinein
        && tableNo.value !== ''
      ) {
        blockStore.value = true
      }
    }

    if (props.outletInfo) {
      outletDetails.value = props.outletInfo
      blocOrUnblock()
    }

    const userData = ref({
      user: { first_name: '', last_name: '', user_name: '' },
    })

    // userService.getStoreDetails().then(res => {
    //   if (res.data) {
    //     outletDetails.value = res.data
    //   }
    // })

    watch(store.state.outlet, newState => {
      outletDetails.value = newState.outletInfo
      blocOrUnblock()
    })

    watch(resultQuery, newState => {
      store.dispatch('user/updateMenuSearch', newState)
    })

    userService.activeUserRequest().then(res => {
      if (res.data) {
        store.state.user.activeUser = res.data
        store.state.user.isLoggedIn = true
      }
    })

    const sumbitRegistraion = () => {
      userService.registrationRequest(userData.value).then(res => {
        store.state.user.activeUser = res.data
        store.state.user.isLoggedIn = true
        isSignUp.value = false
      })
    }
    const signOut = () => {
      userService.signOutRequest().then(() => {
        store.state.user.isLoggedIn = false
        store.state.user.activeUser = {}
      })
    }

    const navigateToHome = () => {
      if (tableNo.value !== '') {
        router.push({ name: 'home', params: { table_no: tableNo.value } })
      } else {
        router.push({ name: 'home' })
      }
    }

    const formatval = vals => {
      const outp = moment(vals, 'hh:mm').format('HH:mm')
      return outp
    }

    return {
      isSignUp,
      isSignIn,
      signOut,
      signIn,
      userData,
      sumbitRegistraion,
      required,
      outletDetails,
      email,
      tableNo,
      navigateToHome,
      blockStore,
      resultQuery,
      isStoreDetails,
      getallTime,
      formatval,
      routeName,
    }
  },
  data() {
    return {
      imageUrl:
        'https://howaboutthat.in/assets/images/works/desktop/ubreathe.jpg',
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outletDetails .white--text{
    font-weight: 800;
    color:#fff !important;
}
</style>
